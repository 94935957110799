<template>
  <img
    class="Paddle__logo"
    :src="require('images/voicemod-white.svg')"
    alt="Voicemod logo"
  >
</template>
<script>
import { paddleLoader } from '@/services/paddle';
import { ROUTER_PATHS } from '@/router/paths';
import { mParticleMetric } from '@/metrics';
export default {
    props: {
        productId: {
            type: Number,
            required: true,
        },
        email: {
            type: String,
            required: true,
        },
        userId: {
            type: String,
            required: true,
        },
        url: {
            type: String,
            required: true,
        },
        eventProperties: {
            type: Object,
            required: true,
        },
    },
    async mounted() {
        await paddleLoader.loadPaddleClassic();
        /* global Paddle */
        if (process.env.VUE_APP_ENVIRONMENT !== 'production') {
            Paddle.Environment.set('sandbox');
        }

        const commonProperties = this.eventProperties;

        Paddle.Setup({
            vendor: 15612,
            eventCallback: function(data) {
                if (data.event === 'Checkout.Complete') {
                    const obj = {
                        ...commonProperties,
                        purchase_id: data.eventData.checkout.id,
                        final_price: data.eventData.checkout.prices.customer.total,
                        final_currency: data.eventData.checkout.prices.customer.currency,
                        from_ad: false,
                        reseller: 'paddle',
                    };
                    mParticleMetric.track(mParticleMetric.EVENTS.ORDER_COMPLETED, obj);
                    mParticleMetric.track(mParticleMetric.EVENTS.CHECKOUT_COMPLETED, obj);
                    window.dataLayer.push({
                        event: 'Converted to Pro',
                        value: data.eventData.checkout.prices.customer.total,
                        currency: data.eventData.checkout.prices.customer.currency,
                        send_to: 'AW-659377836/d5cpCKXti-4BEKydtboC',
                    });
                    if (window.gtag) {
                        window.gtag('event', 'conversion', {
                            'send_to': 'AW-659377836/d5cpCKXti-4BEKydtboC',
                            'value': data.eventData.checkout.prices.customer.total,
                            'currency': data.eventData.checkout.prices.customer.currency,
                        });
                    }
                }
                console.log(data.eventData);
                mParticleMetric.track(`Paddle ${data.event.replaceAll('.', ' ')}`, {
                    ...data.eventData,
                    ...commonProperties,
                });
            },
        });

        const checkoutOptions = {
            product: this.productId,
            marketingConsent: false,
            disableLogout: true,
            override: this.url,
            allowQuantity: false,
            successCallback: () => {
                this.$router.push({ path: ROUTER_PATHS.PADDLE_PAYMENT_CHECK, query: {
                    userId: this.userId,
                    productId: this.productId,
                }});
            },
            closeCallback: () => {
                this.$router.push({ path: ROUTER_PATHS.PAYMENT_ERROR });
            },
        };

        Paddle.Checkout.open(checkoutOptions);
    },
};
</script>
<style lang="scss" src="./PaddlePaymentComponent.scss" />