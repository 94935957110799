const loadPaddleClassic = async () => {
    const paddleClassic = document.createElement('script');
    paddleClassic.setAttribute('src', 'https://cdn.paddle.com/paddle/paddle.js');
    document.head.appendChild(paddleClassic);

    return new Promise((resolve) => {
        paddleClassic.onload = () => {
            resolve();
        };
    });
};

const loadPaddleBilling = async () => {
    const paddleBilling = document.createElement('script');
    paddleBilling.setAttribute('src', 'https://cdn.paddle.com/paddle/v2/paddle.js');
    document.head.appendChild(paddleBilling);
    return new Promise((resolve) => {
        paddleBilling.onload = () => {
            resolve();
        };
    });
};

const paddleLoader = {
    loadPaddleClassic,
    loadPaddleBilling,
};

export { paddleLoader };