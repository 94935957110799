<template>
  <img
    class="Paddle__logo"
    :src="require('images/voicemod-white.svg')"
    alt="Voicemod logo"
  >
</template>
<script>
import { paddleLoader } from '@/services/paddle';
import { ROUTER_PATHS } from '@/router/paths';
import { mParticleMetric } from '@/metrics';
export default {
    props: {
        productId: {
            type: String,
            required: true,
        },
        email: {
            type: String,
            required: true,
        },
        userId: {
            type: String,
            required: true,
        },
        token: {
            type: String,
            required: true,
        },
        eventProperties: {
            type: Object,
            required: true,
        },
    },
    async mounted() {
        await paddleLoader.loadPaddleBilling();
        /* global Paddle */
        if (process.env.VUE_APP_ENVIRONMENT !== 'production') {
            Paddle.Environment.set('sandbox');
        }

        const commonProperties = this.eventProperties;
        Paddle.Initialize({
            token: process.env.VUE_APP_PADDLE_BILLING_TOKEN,
            eventCallback: function(eventData) {
                if (eventData.name === 'checkout.completed') {
                    const obj = {
                        ...commonProperties,
                        purchase_id: eventData.data.transaction_id,
                        checkout_id: eventData.data.transaction_id,
                        final_price: eventData.data.totals.total,
                        final_currency: eventData.data.currency_code,
                        payment_method: eventData.data?.payment?.method_details?.type,
                        from_ad: false,
                        reseller: 'paddle',
                    };
                    mParticleMetric.track(mParticleMetric.EVENTS.ORDER_COMPLETED, obj);
                    mParticleMetric.track(mParticleMetric.EVENTS.CHECKOUT_COMPLETED, obj);
                    window.dataLayer.push({
                        event: 'Converted to Pro',
                        value: eventData.data.totals.total,
                        currency: eventData.data.currency_code,
                        send_to: 'AW-659377836/d5cpCKXti-4BEKydtboC',
                    });
                    if (window.gtag) {
                        window.gtag('event', 'conversion', {
                            'send_to': 'AW-659377836/d5cpCKXti-4BEKydtboC',
                            'value': eventData.data.totals.total,
                            'currency': eventData.data.currency_code,
                        });
                    }
                }
                console.log(eventData.data);

                const splitedEventName = eventData.name.split('.');

                const capitalizedEventName = splitedEventName.map((name) => name.charAt(0).toUpperCase() + name.slice(1)).join(' ');

                const curatedEventData = {};
                Object.keys(eventData.data).forEach((key) => {
                    if (key === 'currency_code') {
                        curatedEventData.currency = eventData.data[key];
                    }
                    if (key === 'transaction_id') {
                        curatedEventData.purchase_id = eventData.data[key];
                    }
                });

                mParticleMetric.track(`Paddle ${capitalizedEventName}`, {
                    ...curatedEventData,
                    checkout_id: eventData.data.transaction_id,
                    payment_method: eventData.data?.payment?.method_details?.type,
                    purchase_id: eventData.data.transaction_id,
                    ...commonProperties,
                });
            },
        });
        const checkoutOptions = {
            transactionId: this.token,
            settings: {
                successUrl: `${location.origin}/#${ROUTER_PATHS.PADDLE_PAYMENT_CHECK}?productId=${this.productId}&userId=${this.userId}&client=flagship`,
                allowLogout: false,
            },
            successCallback: () => {
                this.$router.push({ path: ROUTER_PATHS.PADDLE_PAYMENT_CHECK, query: {
                    userId: this.userId,
                    productId: this.productId,
                }});
            },
            closeCallback: () => {
                this.$router.push({ path: ROUTER_PATHS.PAYMENT_ERROR });
            },
        };

        Paddle.Checkout.open(checkoutOptions);
    },
};
</script>
<style lang="scss" src="./PaddlePaymentComponent.scss" />